import { ConsoleSqlOutlined } from "@ant-design/icons";
import { parseEther } from "@ethersproject/units";
import { Typography, Layout, Progress, Button, notification } from "antd";
import React, { useState, useEffect } from "react";
import { CITYDAO_ADDRESS, CORS_ENDPOINT} from "../constants"
import { Card } from "./"

const { ethers } = require("ethers");
const { Text } = Typography;
const { Content } = Layout;

// displays a timeline for scaffold-eth usage

export default function CitizenNft({contract, address, signer, tokenCount, useContractReader, loadWeb3Modal, web3Modal, oldCitizenCounts, newCitizenCounts}) {
  const [amount, setAmount] = useState(0.25);
  const [nftNumber, setNftNumber] = useState(1)
  const [assetQuantity, setAssetQuantity] = useState(0)
  const [newCitizenCount, setNewCitizenCount] = useState(0)
  const [oldCitizenCount, setOldCitizenCount] = useState(0)

  useEffect(() => {
    if(newCitizenCounts == 0) {
      return;
    }
    setNewCitizenCount(newCitizenCounts[0].toNumber() + newCitizenCounts[1].toNumber() + newCitizenCounts[2].toNumber())
  }, [newCitizenCounts])

  useEffect(() => {
    if(oldCitizenCounts == 0) {
      return;
    }
    setOldCitizenCount(oldCitizenCounts[0].toNumber() + oldCitizenCounts[1].toNumber() + oldCitizenCounts[2].toNumber())
  }, [oldCitizenCounts])

  const citidao_param = [CITYDAO_ADDRESS, 42]

  const objOtherTokenCount = useContractReader(contract, "CitizenNFT", "balanceOf", citidao_param);
  const [otherTokenCount, setOtherTokenCount] = useState(0)

  useEffect(() => {
    if(objOtherTokenCount != undefined) {
      setOtherTokenCount(parseInt(objOtherTokenCount["_hex"]))
    }
  }, [objOtherTokenCount]);

  const objReservedTokenCount = useContractReader(contract, "CitizenNFT", "howManyReservedCitizenships");
  const [ownReservedCount, setReservedTokenCount] = useState(0)

  useEffect(() => {
    if(objReservedTokenCount != undefined) {
      setReservedTokenCount(parseInt(objReservedTokenCount["_hex"]))
    }
  }, [objReservedTokenCount]);

  const handleBuyMint = async () => {
    try {
      const costFunction = contract["CitizenNFT"].connect(signer)["inquireCostOfEntry"]
      const costRequest = await costFunction()
      setAmount(parseFloat(costRequest["_hex"]))
      const buyFunction = contract["CitizenNFT"].connect(signer)["onlineApplicationForCitizenship"]
      const hash = await buyFunction(nftNumber, {value: parseEther(amount.toString())})
      await hash.wait()
      notification.success({
        message: "1 NFT minted",
        placement: "bottomRight",
      });
    } catch(e) {
      if(e.code == "UNPREDICTABLE_GAS_LIMIT") {
        notification.error({
          message: "Cannot estimate gas limit",
          placement: "bottomRight",
        });
      } else if (e.code == "INSUFFICIENT_FUNDS") {
        notification.error({
          message: "Not enough funds",
          placement: "bottomRight",
        });
      } else if (e.code == "4001") {
        notification.error({
          message: "You denied transaction signature",
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: e.message,
          placement: "bottomRight",
        });
      }
    }
  }

  return (
    <Content className="citizen">
      <Text className="title roboto">Claim CityDAO Citizenship</Text>
      <Text className="connect_wallet roboto">Connect your wallet, buy the NFT, and join us in shaping the future of land</Text>
      <Text className="sold_amount roboto">Your Citizen NFTs: { newCitizenCount + oldCitizenCount }</Text>
      <Progress percent={((tokenCount - (otherTokenCount - 1) + ownReservedCount) * 100 / tokenCount)} size="small" style={{width: "100%", maxWidth: "364px"}} showInfo={false} trailColor={"#DBDBDB"} strokeColor={"#06BE7F"} className="sold_value" />
      {(tokenCount - (otherTokenCount - 1) + ownReservedCount) < tokenCount ? (
        <Text className="token_amount roboto">{tokenCount - (otherTokenCount - 1) + ownReservedCount} minted / {tokenCount}</Text>
      )
      :
      (
        <Text className="token_amount roboto">Sold Out</Text>
      )}
      
      <Card
          loadWeb3Modal={loadWeb3Modal}
          web3Modal={web3Modal}
          mint={true}
          type={0}
          disable={tokenCount - (otherTokenCount - 1) + ownReservedCount >= tokenCount ? true : false}
          handleBuyMint={handleBuyMint}
          />
    </Content>
  );
}