import { ConsoleSqlOutlined } from "@ant-design/icons";
import { parseEther } from "@ethersproject/units";
import { Typography, Layout, Tooltip, Progress, Button, notification } from "antd";
import React, { useState, useEffect } from "react";
import { CITYDAO_ADDRESS, ETHERSCAN_KEY, API_ENDPOINT, CORS_ENDPOINT, CONTRACT_ADDRESS } from "../constants";

const { ethers } = require("ethers");
const { Text } = Typography;
const { Content } = Layout;

// displays a timeline for scaffold-eth usage

export default function Card({ loadWeb3Modal, web3Modal, mint, type, disable, handleBuyMint, nftCounts }) {
  const [buying, setBuying] = useState(false);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [nftCount, setNftCount] = useState(0)
  console.log(disable)

  useEffect(() => {
    if (type == 0) {
      setTitle("Citizen NFT");
      setImage("/citizen.jpg");
    } else if (type == 1) {
      setTitle("Founding Citizen NFT");
      setImage("/founding.png");
    } else {
      setTitle("First Citizen NFT");
      setImage("/first.png");
    }
  }, []);

  useEffect(() => {
    if(nftCounts == 0 || nftCounts == undefined) {
      return;
    }
    setNftCount(nftCounts[type].toNumber())
  }, [nftCounts])

  const handleButtonClick = async () => {
    setBuying(true);
    await handleBuyMint(type);
    setBuying(false);
  };

  return (
    <Content className="card">
      <Content className="mint_nft">
        <img src={image} />
        <Content className="nft_info">
          <Text className="nft_title space medium">{title}</Text>
          {mint && (
            <div className="information">
              <Text className="nft_price roboto">Price: 0.25 ETH</Text>
              <Tooltip title="Supply can be increased through a DAO vote">
                <Text className="nft_supply roboto">Supply: 10,000+</Text>
              </Tooltip>
            </div>
          )}

          <Text className="nft_special roboto">
            Special privileges: Early access to participate in CityDAO activities like land drops, access to
            Citizens-only Discord channels, voting power. Does not grant ownership of land.
          </Text>
        </Content>
      </Content>
      { mint ? (
        <>
          {web3Modal && !web3Modal.cachedProvider ? (
            <Button
              key="loginbutton"
              style={{ verticalAlign: "top", border: "none" }}
              /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
              onClick={loadWeb3Modal}
              className="connect_status btn_mint"
            >
              Connect Wallet
            </Button>
          ) : (
            <>
              <Button className="btn_mint roboto" onClick={handleButtonClick} disabled={buying || (!mint && nftCount == 0)} hidden={disable}>
                {!buying ? mint ? <>MINT NFT</> : <>EXCHANGE { nftCount } NFT</> : mint ? <>Minting...</> : <>Exchanging...</>}
              </Button>
              <a
                className="btn_mint my-8 roboto"
                href="https://rarible.com/token/0x7eef591a6cc0403b9652e98e88476fe1bf31ddeb:42"
              >
                Buy on Rarible
              </a>
            </>
          )}
        </>
      )
      :
      (
        <>
          {web3Modal && !web3Modal.cachedProvider ? (
            <Button
              key="loginbutton"
              style={{ verticalAlign: "top", border: "none" }}
              /* type={minimized ? "default" : "primary"}     too many people just defaulting to MM and having a bad time */
              onClick={loadWeb3Modal}
              className="connect_status btn_mint"
            >
              Connect Wallet
            </Button>
          ) : (
            <Button className="btn_mint roboto" onClick={handleButtonClick} disabled={buying || (!mint && nftCount == 0) || disable}>
              {!buying ? mint ? <>MINT NFT</> : <>EXCHANGE { nftCount } NFT</> : mint ? <>Minting...</> : <>Exchanging...</>}
            </Button>
          )}
        </>
      )}
    </Content>
  );
}
