import { Typography, Layout, notification } from "antd";
import React, { useState, useEffect } from "react";
import { CONTRACT_ADDRESS, NFT_IDS } from "../constants"
import { Card } from "./"
import NFT from "../contracts/NFT.json"

const { ethers } = require("ethers");
const { Text } = Typography;
const { Content } = Layout;

export default function Exchange({contract, address, signer, loadWeb3Modal, web3Modal, handleApprove, oldCitizenCount}) {

  const handleBuyMint = async (type) => {
    try {
      if(oldCitizenCount[type].toNumber() == 0) {
        notification.info({
          message: "Not found old Citizen NFTs",
          placement: "bottomRight",
        });
        return;
      }
      const objNFT = new ethers.Contract(CONTRACT_ADDRESS[1], NFT, signer)
      let approve = await objNFT["isApprovedForAll"](address, contract["ExchangeNFT"]["address"]);
      if(!approve) {
        await handleApprove(CONTRACT_ADDRESS[1], contract["ExchangeNFT"]["address"])
      }
      const buyFunction = contract["ExchangeNFT"].connect(signer)["exchangeNFT"]
      const hash = await buyFunction(CONTRACT_ADDRESS[1], NFT_IDS[1][type])
      await hash.wait()
      notification.success({
        message: "Successfully exchanged",
        placement: "bottomRight",
      });
    } catch(e) {
      console.log(e)
      if(e.code == "UNPREDICTABLE_GAS_LIMIT") {
        notification.error({
          message: "Cannot estimate gas limit",
          placement: "bottomRight",
        });
      } else if (e.code == "INSUFFICIENT_FUNDS") {
        notification.error({
          message: "Not enough funds",
          placement: "bottomRight",
        });
      } else if (e.code == "4001") {
        notification.error({
          message: "You denied transaction signature",
          placement: "bottomRight",
        });
      } else {
        notification.error({
          message: e.message,
          placement: "bottomRight",
        });
      }
    }
  }

  return (
    <Content className="citizen">
      <Text className="title roboto">Exchange Legacy Citizenship NFTs</Text>
      <Text className="connect_wallet roboto">Connect your wallet and migrate your existing OS Citizenship NFTs to our new contract.</Text>
      <Content className="cards">
        <Card
          loadWeb3Modal={loadWeb3Modal}
          web3Modal={web3Modal}
          mint={false}
          type={0}
          disable={false}
          handleBuyMint={handleBuyMint}
          nftCounts={oldCitizenCount}
          />
        <Card
          loadWeb3Modal={loadWeb3Modal}
          web3Modal={web3Modal}
          mint={false}
          type={1}
          disable={false}
          handleBuyMint={handleBuyMint}
          nftCounts={oldCitizenCount}
          />
        <Card
          loadWeb3Modal={loadWeb3Modal}
          web3Modal={web3Modal}
          mint={false}
          type={2}
          disable={false}
          handleBuyMint={handleBuyMint}
          nftCounts={oldCitizenCount}
          />
      </Content>
    </Content>
  );
}